import http from "@/api/http";
class LoadService {
  create(data: any, fields?: string) {
    return http.post("/load", data, {
      params: {
        select: fields
      }
    });
  }
  getAll(storageId: number, filters: any, fields?: string) {
    return http.get(`/load/storage/${storageId}`, {
      params: {
        select: fields,
        ...filters
      }
    });
  }
  getHistorial(storageId: number, filters: any, fields?: string) {
    return http.get(`/load/storage/${storageId}/historial`, {
      params: {
        select: fields,
        ...filters
      }
    });
  }
  get(loadId: number, fields?: string) {
    return http.get(`/load/${loadId}`, {
      params: {
        select: fields
      }
    });
  }
  update(loadId: number, data: any, fields?: string) {
    return http.patch(`/load/${loadId}`, data, {
      params: {
        select: fields
      }
    });
  }
  delete(loadId: number) {
    return http.delete(`/load/${loadId}`);
  }
}

export default new LoadService();
