

















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import LotService from "@/api/lots";
import LoadService from "@/api/loads";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters("UserModule", {
      storage: "getStorage"
    }),
    ...mapGetters("LotModule", {
      lots: "getLots"
    })
  }
})
export default class AgregarLoad extends Vue {
  isHovering = false;
  checkedLots = [];
  storage!: any;
  lots!: any[];
  updateKey = 0;

  getDetail(row: any) {
    if (!Number.isInteger(row.items[0])) {
      return;
    }
    LotService.get(row.id).then(response => {
      const index = this.lots.findIndex(x => x.id == row.id);
      this.lots[index].items = response.data.items;
      this.updateKey += 1;
    });
  }
  get totalWeight() {
    return this.checkedLots
      .reduce((sum: number, current: any) => {
        return sum + current.weight;
      }, 0)
      .toFixed(3);
  }

  get routeName() {
    return this.$route.name;
  }

  @Watch("totalWeight")
  checkLimit(val: number): void {
    if (val > 28500) {
      this.$buefy.notification.open({
        message:
          "El camión no puede llevar mas de 28 toneladas y media \n se removerá el último lote agregado",
        type: "is-danger"
      });
      this.checkedLots.pop();
    }
  }

  createLoad() {
    this.$buefy.dialog.confirm({
      message: `¿Crear carga?`,
      confirmText: "Crear",
      cancelText: "Cancelar",
      onConfirm: () => {
        LoadService.create({
          lots: this.checkedLots.map((x: any) => x.id),
          storage_id: this.storage.id
        }).then(() => {
          this.$buefy.notification.open({
            message: "Se ha creado la carga",
            type: "is-success"
          });
          LoadService.getAll(this.storage.id, {
            per_page: 11,
            current_page: 1
          }).then(response => {
            this.$store.dispatch("LoadModule/setLoads", response.data.data);
            this.$router.push({ name: "Cargas" });
          });
        });
      }
    });
  }

  updateLoad() {
    LoadService.update(parseInt(this.$route.params.loadId), {
      lots: this.checkedLots.map((x: any) => x.id)
    }).then(() => {
      this.$buefy.notification.open({
        message: "Se ha actualizado la carga",
        type: "is-success"
      });
      LoadService.getAll(this.storage.id, {
        per_page: 11,
        current_page: 1
      }).then(response => {
        this.$store.dispatch("LoadModule/setLoads", response.data.data);
        this.$router.push({ name: "Cargas" });
      });
    });
  }

  mounted() {
    LotService.getAll(this.storage.id, {
      current_page: 1,
      per_page: 1000
    }).then(response => {
      this.$store.dispatch("LotModule/setLots", response.data.data);
      if (this.$route.name == "EditarCarga") {
        LoadService.get(parseInt(this.$route.params.loadId)).then(response => {
          this.checkedLots = response.data.lots;
          this.lots.concat(response.data.lots);
          this.$store.dispatch("LotModule/addLots", response.data.lots);
        });
      }
    });
  }
}
